
#app {
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #EDEDED;
}

html {
  font-size: 87.5%; /* reset default 16px => 14px */
}

a {
  text-decoration: none;
}

/*body {*/
/*  overflow: hidden;*/
/*}*/
